import { Container, Grid } from '@material-ui/core'
import React from 'react'
import BmCard from '../components/bm-card'
import Layout from '../components/layout'
import PageHeader from '../components/page-header'
import GridRow from '../components/_grid-row'

import greyImg from '../images/grey-placeholder.png';
import hoodieImg from '../images/merchandise/hoodie.jpg';
import logoPLaceholder from '../images/merchandise/logo-placeholder.jpg';

export default function Merchandise() {
    return (
        <Layout pageName="Merchandise">
            <PageHeader 
                header="Merchandise"
                desc="For more information please send an email to info@beachmonkeyz.com or DM Instagram"
            />
            
            <Container maxWidth="xl">
                <GridRow>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={hoodieImg}
                                cardTitle={{title: 'Hoodies', titleLink: 'mailto:info@beachmonkeyz.com'}}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={logoPLaceholder}
                                cardTitle={{title: 'Sports Bras', titleLink: 'mailto:info@beachmonkeyz.com'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={logoPLaceholder}
                                cardTitle={{title: 'T-Shirts', titleLink: 'mailto:info@beachmonkeyz.com'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={logoPLaceholder}
                                cardTitle={{title: 'Towels', titleLink: 'mailto:info@beachmonkeyz.com'}}
                            />
                        </Grid>
                        
                    </Grid>
                </GridRow>
            </Container>
        </Layout>
    )   
}
